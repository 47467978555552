@use 'breakpoints';

.wrapper {
  height: 64px;
  display: flex;

  @include breakpoints.up('medium') {
    height: 72px;
  }
}

.link {
  display: flex;
  align-items: center;
}