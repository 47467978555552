@use 'theme';
@use 'baseline';
@use 'borders';
@use 'breakpoints';

.wrapper {
  border-left: borders.get();
  padding: theme.spacing(0, 0.25);
}

.button {
  @include baseline.reset-button;
  width: 48px;
  height: 40px;
  display: flex;
  justify-content: center;

  @include breakpoints.up('small') {
    width: 70px;
  }
}

.hamburgerMenu {
  margin-top: theme.spacing(1.5);
  position: relative;
  transform: translateY(theme.spacing(1));
  background: theme.get('textColor');
  transition: all 0ms 300ms;

  &, &::after, &::before {
    display: block;
    height: 1.2px;
    width: 22px;
  }

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0;
    background: theme.get('textColor');
  }

  &::before {
    bottom: theme.spacing(1);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &::after {
    top: theme.spacing(1);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.animate {
    background: rgba(0, 0, 0, 0);
  
    &::after {
      top: 0;
      transform: rotate(45deg);
      transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
  
    &::before {
      bottom: 0;
      transform: rotate(-45deg);
      transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
}
