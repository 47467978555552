@use 'theme';
@use 'breakpoints';
@use 'borders';
@use 'shadows';
@use 'transitions';
@use 'animations';
@use 'gradients';

.wrapper {
  position: absolute;
  right: 0;
  top: theme.spacing(9);
  width: 360px;
  background: gradients.get('background');
  box-shadow: shadows.get('default');
  border-radius: borders.radius(0, 0, 'lg', 'lg');
  @include animations.grow('out');
  transform: translate3d(20%, -25%, 0) scale(0.5, 0.5);

  & + div {
    transition: transitions.get(('opacity', 'visibility', 'transform', 'background'));
  }

  &:hover + div {
    background: gradients.get('background');
  }
}

.container {
  display: inline-block;
  position: relative;
  z-index: auto;

  @include breakpoints.up('small') {
    z-index: 105;

    &:hover .wrapper {
      @include animations.grow('in');
      transition-delay: 0.15s;
    }
    &:hover .products {
      visibility: visible;
    }
  }
}

.products {
  max-height: calc(100vh - 225px);
  visibility: collapse;
  transition-delay: 200ms;
  transition-duration: 400ms;
  overflow-y: scroll;
}

.emptyState {
  padding: theme.spacing(3.75, 8);
  background-color: theme.palette('white');
  border-radius: borders.radius(0, 0, 'md', 'md');
}

.info {
  margin-top: theme.spacing(1);
  line-height: 13px;
  opacity: 0.8;
}

.button {
  border-radius: borders.radius(0, 0, 'xMd', 'xMd');
  margin: theme.spacing(0); // margin: this style is necessary for Safari to display cart button properly (without margins)
}
