@use 'theme';
@use 'fonts';

.wrapper {
  height: 60px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: theme.get('backgroundColor');
}

.name {
  font-weight: theme.weight('bold');
  font-size: fonts.size('compact');
  color: theme.get('textColor');
}

.quantity {
  font-weight: theme.weight('regular');
  display: inline-block;
  color: rgba(theme.get('textColorRgb'), 0.3);
  margin-left: theme.spacing(0.625);
}

.amount {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.net {
  margin: theme.spacing(0);
  font-size: fonts.size('little');
  color: rgba(theme.get('textColorRgb'), 0.8);
}

.gross {
  margin: theme.spacing(0);
  font-size: fonts.size('small');
  color: theme.get('textColor');
  font-weight: theme.weight('bold');
}
