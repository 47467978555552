@use 'theme';
@use 'breakpoints';
@use 'transitions';
@use 'borders';

.wrapper {
  display: none;

  @include breakpoints.up('medium') {
    position: static;
    top: theme.spacing(9);
    left: 0;
    display: block;
    width: 100%;
    height: 56px;
    overflow: visible;
    background-color: theme.get('menuBackground');
    border-radius: theme.get('menuRadius'); 
    transition: transitions.get('border-radius');
    z-index: 99;

    &.opened {
      border-radius: theme.get('menuRadius') theme.get('menuRadius') 0 0;
    }
  }
}

.container {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: visible;
  padding: theme.spacing(0);
}

.menuItemsWrapper {
  display: flex;
  position: relative;
  flex: 1;
}

.menuItems {
  display: flex;
  width: 100%;
  border-radius: theme.get('menuRadius');
  overflow: hidden;
}
