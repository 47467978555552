$breakpoints: (
  'xSmall': 360px,
  'small': 768px,
  'medium': 992px,
  'largeMedium': 1160px,
  'large': 1280px,
  'xLarge': 1360px,
);

@mixin up($key) {
  @media (min-width: map-get($breakpoints, $key)) {
    @content;
  }
}

@mixin down($key) {
  @media (max-width: calc(map-get($breakpoints, $key) - 1px)) {
    @content;
  }
}

@mixin between($firstKey, $secondKey) {
  @media (min-width: map-get($breakpoints, $firstKey)) and (max-width: map-get($breakpoints, $secondKey)) {
    @content;
  }
}
