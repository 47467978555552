@use 'theme';
@use 'baseline';

@layer utilities {
  .link {
    @include baseline.reset-link;
  }
  
  .hover {
    &:hover {
      text-decoration: underline;
    }
  }
  
  .underline {
    text-decoration: underline;
  }
}
