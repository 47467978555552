@use 'theme';
@use 'borders';
@use 'breakpoints';
@use 'animations';
@use 'shadows';

.wrapper {
  position: relative;
}

.wrapperBorder {
  border-bottom: borders.get();
}

.container {
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  padding: theme.spacing(0, 2);

  @include breakpoints.up('small') {
    overflow: hidden;
    white-space: pre-wrap;
    padding: theme.spacing(0, 1);
  }

  @include breakpoints.up('large') {
    padding: theme.spacing(0);
  }
}

.breadcrumbWrapper {
  &:hover .options {
    @include animations.grow('in');
  }

  &:not(.last) {
    & .breadcrumb {
      white-space: nowrap;
    }
  }
  
  &.last {
    @include breakpoints.up('small') {
      & .breadcrumb {
        display: -webkit-box;
        padding: theme.spacing(0);
      }
    }
  }
}

.breadcrumb {
  display: flex;
  padding: theme.spacing(2, 0);
  white-space: nowrap;

  @include breakpoints.up('small') {
    white-space: normal;
  }
}

.options {
  position: absolute;
  top: 53px;
  display: flex;
  flex-direction: column;
  margin-left: theme.spacing(-1.5);
  z-index: 12;
  background-color: theme.palette('white');
  padding: theme.spacing(1.25, 0);
  border-top: borders.get();
  border-bottom-left-radius: borders.radius('md');
  border-bottom-right-radius: borders.radius('md');
  box-shadow: shadows.get('breadcrumbOptions');
  @include animations.grow('out');
}

.selectOption {
  padding: theme.spacing(1, 1.5);
}

.selectAll {
  opacity: 0.6;
}

.arrow {
  display: flex;
  position: relative;
  margin: theme.spacing(0, 2);
  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-right: borders.get($color: theme.get('textColor'));
    border-bottom: borders.get($color: theme.get('textColor'));
    transform: rotate(-45deg);
  }
}
