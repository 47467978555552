@use 'theme';
@use 'fonts';

.input {
  height: 100%;
  width: 100%;
  display: flex;
  padding: theme.spacing(0);
  background-color: transparent;
  border: none;
  outline: none;
  color: theme.get('textColor');
  font-size: fonts.size('xSmall');

  & input::placeholder {
    color: rgba(theme.get('textColorRgb'), 0.9);
  }
}
