@use 'theme';
@use 'borders';
@use 'baseline';
@use 'ripple';
@use 'transitions';

@layer utilities {
  @mixin hover {
    &:hover:not(.disabled):not(.loading), &:active:not(.disabled):not(.loading), &:focus-visible:not(.disabled):not(.loading) {
      @content;
    }
  }

  .button {
    @include baseline.reset-button;
    display: inline-flex;
    border-radius: borders.radius('xMd');
    transition: transitions.get('all', 200ms);
    align-items: center;
    justify-content: center;
    background-position: center;
  }

  .text {
    color: var(--buttonColor);
    @include hover {
      background-color: theme.get('backgroundColor');
    }
    &.disabled {
      cursor: not-allowed;
      background-color: rgba(theme.get('textColorRgb'), 0.4);
    }
  }

  .contained {
    background: var(--buttonColor);
    color: theme.palette('white');
    &.iconFill svg {
      fill: theme.palette('white');
    }
    @include hover {
      background: var(--buttonHoverColor);
    }
    &.disabled {
      cursor: not-allowed;
      background-color: rgba(theme.get('textColorRgb'), 0.4);
    }
  }

  .outlined {
    color: var(--buttonColor);
    border: borders.get($color: var(--buttonColor));
    background: transparent;
    --rippleColor: #{theme.palette('white')};
    @include hover {
      background: var(--buttonColor);
      color: theme.palette('white');
    }
    &.disabled {
      cursor: 'not-allowed';
      color: rgba(theme.get('textColorRgb'), 0.4);
      border-color: rgba(theme.get('textColorRgb'), 0.4);
    }
  }

  .tag {
    &.white {
      background-color: theme.palette('white');
    }
    &:not(.white) {
      background: theme.get('backgroundColor');
    }
    border: borders.get($color: theme.get('backgroundColor'));
    color: theme.get('textColor');
    --rippleColor: #{theme.palette('black')};
    &.iconFill svg {
      fill: theme.get('textColor');
    }
    @include hover {
      background: theme.palette('white');
      border: borders.get($color: theme.get('activeColor'));
    }
    &.disabled {
      cursor: not-allowed;
      background-color: rgba(theme.get('textColorRgb'), 0.4);
    }
  }

  .ripple {
    @include ripple.ripple-effect;
  }

  .sm {
    padding: theme.spacing(1, 4);
    min-height: 31px;

    &.normal {
      padding: theme.spacing(1, 1.5);
    }
  }

  .md {
    padding: theme.spacing(1, 2);
    min-height: 44px;
  }

  .lg {
    padding: theme.spacing(2, 8);
  }

  .onlyIcon {
    padding: theme.spacing(2);

    &.sm {
      padding: theme.spacing(1.375);
    }
  }

  .wide {
    width: 100%;
    justify-content: center;
    padding-left: theme.spacing(0);
    padding-right: theme.spacing(0);
  }

  .round {
    border-radius: 28px;

    &.md {
      border-radius: theme.get('buttonMdRoundRadius');
      padding-left: theme.spacing(3);
      padding-right: theme.spacing(3);
    }

    &.sm {
      border-radius: 16px;
    }
  }

  .icon {
    margin-right: theme.spacing(1);
    & > * {
      display: block;
    }
  }

  .iconLeft {
    display: flex;
    align-items: center;
    position: absolute;
    left: -24px;

    .lg & {
      left: -40px;
    }
  }

  .iconRight {
    position: absolute;
    right: -20px;

    .lg & {
      right: -36px; 
    }
  }

  .loading {
    cursor: progress;
  }

  .primary {
    --buttonColor: #{theme.get('primaryButtonBackground')};
    --rippleColor: #{theme.palette('black')};
    --buttonHoverColor: #{theme.get('primaryButtonHoverBackground')};
  }

  .error {
    --buttonColor: #{theme.palette('error')};
    --rippleColor: #{theme.palette('white')};
    --buttonHoverColor: #{rgba(theme.palette('error'), 0.8)};
  }

  .secondary {
    --buttonColor: #{theme.get('darkDividerColor')};
    --rippleColor: #{theme.palette('white')};
    --buttonHoverColor: #{theme.get('secondaryButtonHoverBackground')};
  }

  .white {
    --buttonColor: #{theme.palette('white')};
    --rippleColor: #{theme.palette('white')};
    --buttonHoverColor: #{theme.get('secondaryButtonHoverBackground')};
  }

  .tertiary {
    --buttonColor: #{theme.get('tertiaryButtonBackground')};
    --rippleColor: #{theme.palette('black')};
    --buttonHoverColor: #{theme.get('tertiaryButtonHoverBackground')};
  }

  .quaternary {
    --buttonColor: #{theme.get('activeColor')};
    --rippleColor: #{theme.palette('white')};
    --buttonHoverColor: #{theme.get('activeColor')};
  }

  .textWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .contentInner {
    display: flex;
    align-items: center;
    opacity: 1;
    transform: scale(1, 1);
    transition: transitions.get(('opacity', 'transform'));

    &.contentHidden {
      opacity: 0;
      transform: scale(0, 0);
    }
  }

  .loader {
    position: absolute;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    &.contentHidden svg {
      animation-play-state: paused;
    }
  }
}
