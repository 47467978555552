@use 'theme';
@use 'borders';

@mixin reset-button {
  outline: none;
  border: none;
  font-family: Lato;
  background: none;
  cursor: pointer;
  padding: theme.spacing(0);

  &:focus-visible {
    color: theme.palette('black');
    outline: borders.get($color: theme.palette('black'));
  }
}

@mixin reset-list {
  padding: theme.spacing(0);
  margin: theme.spacing(0);
  list-style: none;
}

@mixin reset-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
