@use 'theme';
@use 'breakpoints';
@use 'transitions';

$footerOverscanHeight: 300px;

.wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
  -webkit-overflow-scrolling: touch;
}

.overlay {
  position: absolute;
  left: -50vw;
  width: 150vw;
  z-index: 103;
  top: 0;
  background-color: theme.get('overlayBackground');
  visibility: visible;
  transition: transitions.get('opacity', 600ms);
  opacity: 0;
}

.menuContainer {
  position: relative;
  @include breakpoints.up('small') {
    z-index: 104;
  }
}

.mainMenuWrapper {
  display: none;

  @include breakpoints.up('small') {
    display: block;
  }
}

.footerOverscan {
  position: absolute;
  width: 100%;
  height: $footerOverscanHeight;
  margin-top: -$footerOverscanHeight;
  z-index: -1;
}
