@use 'theme';
@use 'fonts';
@use 'breakpoints';
@use 'borders';
@use 'transitions';
@use 'display';

.wrapper {
  cursor: default;
  user-select: none;
  margin-bottom: theme.spacing(0.5);
  transition: transitions.get(('background-color', 'border-radius'));

  @include breakpoints.up('medium') {
    background-color: theme.get('menuBackground');
    border-radius: theme.get('menuRadius');
    padding: theme.spacing(0, 1);
    margin-bottom: theme.spacing(0);

    &.highlight {
      color: theme.get('menuHighLightItemsColor');
      background: theme.get('menuHighLightItemsBackground');
      border-radius: borders.radius(0);

      @include display.except('medium', 'largeMedium');
    }

    &:first-child {
      padding-left: theme.spacing(1.5);
    }

    &:last-child {
      padding-right: theme.spacing(1.5);
    }
      
    // last item is not real item, just popupwrapper
    &:not(:nth-last-of-type(1)) {
      flex-grow: 1;
      min-width: fit-content;
    }

    &:hover {
      color: rgba(theme.get('textColorRgb'), 0.1);

      & .item {
        opacity: 1;
      }

      & .arrow {
        opacity: 1;
      }
    }
  }

  @include breakpoints.up('xLarge') {
    &:not(:nth-last-of-type(1)) .item {
      justify-content: center;
      align-items: center;
    }
  }
}


.itemWrapper {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: space-between;
  background-color: theme.palette('white');
  padding: theme.spacing(3, 3.5);
  border-radius: borders.radius('xMd');
  cursor: pointer;

  @include breakpoints.up('medium') {
    background-color: transparent;
    padding: theme.spacing(0);
    border-radius: 0;
  }

  .item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    transition: transitions.get(('opacity', 'color'));
  
    @include breakpoints.up('medium') {
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: theme.palette('white');
      opacity: 0.75;
    }
  }
  
  & .itemChild {
    display: flex;
    align-items: center;
  
    @include breakpoints.up('medium') {
      flex-direction: column;
      & .icon {
        margin-right: theme.spacing(0);
        margin-bottom: theme.spacing(0.5);
      }
    }

    @include breakpoints.up('xLarge') {
      flex-direction: row;
      & .icon {
        margin-right: theme.spacing(1);
        margin-bottom: theme.spacing(0);
      }
    }
  }
  
  & .dotIcon {
    padding: theme.spacing(0, 1);
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-right: theme.spacing(2.5);
    filter: theme.get('menuIconFilter');

    @include breakpoints.up('medium') {
      filter: none;
      margin-right: theme.spacing(1);
    }
  }

  & .highlightIcon {
    filter: invert(1);

    @include breakpoints.up('medium') {
      filter: none;
    }
  }

  & .text {
    line-height: normal;

    &:has(.arrow) {
      @include breakpoints.between('medium', 'xLarge') {
        margin-left: theme.spacing(1.5);
      }
    }
  }

  & .openedText {
    font-size: fonts.size('large');
    font-weight: fonts.weight('bold');
  }

  & .arrow {
    @include display.up('medium', inline);

    @include breakpoints.up('medium') {
      transform: rotate(90deg);
      transition: transitions.get(('opacity', 'fill'), 200ms);
      margin-left: theme.spacing(1);
      fill: theme.palette('white');
      opacity: 0;
    }
  }
}
