@use 'theme';
@use 'breakpoints';

.container {
  padding: theme.spacing(0, 2);
  margin: theme.spacing(0, auto);
  width: 100%;

  @include breakpoints.up('small') {
    padding: theme.spacing(0, 1);
    max-width: 768px;
  }

  @include breakpoints.up('medium') {
    max-width: 960px;
  }

  @include breakpoints.up('largeMedium') {
    max-width: 1140px;
  }

  @include breakpoints.up('large') {
    max-width: 1260px;
    padding: theme.spacing(0);
  }

  @include breakpoints.up('xLarge') {
    max-width: 1340px;
  }
}
