@use 'theme';
@use 'borders';
@use 'breakpoints';
@use 'display';

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchWrapper {
  margin-right: theme.spacing(2);

  @include breakpoints.up('medium') {
    width: 400px;
    z-index: 105;
  }

  &:not(.withMainPartnerLogo) {
    @include breakpoints.up('largeMedium') {
      width: 500px;
    }
  }

  &.withMainPartnerLogo {
    @include breakpoints.up('large') {
      width: 500px;
    }
  }
}

.leftSide {
  display: flex;
}

.contactPhone {
  display: none;
  flex-direction: column;
  gap: theme.spacing(0.25, 0);
  padding-left: theme.spacing(1.5);
  margin-left: theme.spacing(4);
  margin-right: theme.spacing(3);
  border-left: borders.get($color: theme.get('darkDividerColor'));
  min-width: 120px;

  @include breakpoints.up('medium') {
    display: flex;
  }
}

.content {
  display: none;
  align-items: center;
  z-index: 100;
  height: 100%;

  @include breakpoints.up('medium') {
    display: flex;
    z-index: auto;
  }
}

.icons {
  display: flex;
  height: 100%;
}

.logoWrapper {
  display: flex;
  align-items: center;
  margin: theme.spacing(0);
}

.mainPartnerLogo {
  @include display.up('largeMedium', flex);
  align-items: center;

  margin-left: theme.spacing(4);
}

.logoAnchor {
  display: flex;
}

.mobileActions {
  @include display.down('medium', flex);
  align-items: center;
  gap: theme.spacing(0, 0.5);
}

.mobileSearch {
  @include display.down('medium');
  position: relative;
  z-index: 5;
}
