@use 'theme';
@use 'transitions';

@layer utilities {
  .wrapper {
    display: var(--display);
    justify-content: center;
  }

  .picture {
    position: relative;
    display: flex;
  }

  .fillCoverPicture {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    margin: theme.spacing(0);
  }

  .image {
    opacity: 0;
  }

  .imageScaling {
    width: var(--width, auto);
    height: var(--height, auto);
  }

  .filler {
    @extend .imageScaling;
    display: flex;
  }

  .fillCoverImage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: theme.spacing(0);
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .objectFit {
    object-fit: var(--objectFit);
  }

  .hasBlendMode {
    mix-blend-mode: multiply;
  }

  .loaded {
    opacity: 1;
  }

  .hide {
    opacity: 0;
    z-index: -1;
  }

  .hasFade {
    transition: transitions.get('opacity');
  }

  .skeleton {
    position: absolute;
    top: 0;
  }

  .noscriptImageWithFiller {
    position: absolute;
  }
}