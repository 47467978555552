@use 'transitions';

@mixin grow($type) {
  @if $type == 'in' {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  } @else if $type == 'out' {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -25%, 0) scale(0.9, 0.5);
    transition: transitions.get(('opacity', 'visibility', 'transform'));
  }
}
