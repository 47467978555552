@use 'theme';
@use 'fonts';
@use 'borders';
@use 'breakpoints';
@use 'transitions';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 44px;
  cursor: pointer;
  transition: transitions.get('background-color');

  @include breakpoints.up('small') {
    width: 70px;

    &:hover {
      background-color: theme.get('backgroundColor');
    }
  }
}

.wrapper {
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 32px;
  width: auto;
  color: theme.get('textColor');
}

.sticker {
  min-width: 18px;
  height: 18px;
  margin: theme.spacing(0);
  padding: theme.spacing(0, 0.625);
  position: absolute;
  top: 0;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  line-height: 16px;
  font-size: fonts.size('nano');
}

.label {
  border: borders.get($color: theme.palette('white'));
  background-color: var(--badgeColor);
  color: theme.palette('white');
}

.loader {
  right: -15px;
}
