@use 'theme';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shine {
  0% {
    mask-position: -50%;
  };
  30% {
    mask-position: -50%;
  };
  70% {
    mask-position: 150%;
  };
  100% {
    mask-position: 150%;
  };
}

@layer utilities {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.white {
      background-color: theme.palette('white');
    }

    &.gray {
      background-color: theme.get('backgroundColor');
    }

    &.transparent {
      background-color: inherit;
    }

    &.isExternal {
      .loaderWrapper {
        filter: brightness(0.9);
        transform: scale(0.8);
      }
      .shine {
        filter: brightness(0.8);
        opacity: 0.4;
      }
      .loader {
        opacity: 0.2;
      }
    }
  }

  .hasFade {
    animation: fadeIn 0.2s ease;
  }

  .loaderWrapper {
    display: flex;
    position: relative;
  }

  .loader {
    object-fit: contain;
    color: var(--shineBackground, theme.get('secondaryBackgroundColor'));
    fill: var(--shineBackground, theme.get('secondaryBackgroundColor'));
  }

  .shine {
    object-fit: contain;
    position: absolute;
    fill: rgba(var(--shineColor, theme.get('textColorRgb')), var(--shineOpacity, 0.3));
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0) 35%,
      rgba(255, 255, 255, 0.5) 45%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.5) 55%,
      rgba(255, 255, 255, 0) 65%,
      transparent 100%
    );
    mask-size: 200%;
    animation: shine 2s ease-in-out infinite reverse;

    &.paused {
      animation-play-state: paused;
    }
  }
}