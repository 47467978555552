@use 'theme';
@use 'fonts';
@use 'borders';
@use 'gradients';
@use 'breakpoints';
@use 'display';
@use 'baseline';
@use 'transitions';
@use 'animations';

.focused {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102;

  @include breakpoints.up('small') {
    position: static;
    top: initial;
    left: initial;
    width: auto;
    height: auto;
    z-index: initial;
  }

  & .wrapper {
    position: static;
    border-radius: 0;
    padding: theme.spacing(3.5, 2);
    &.searchBoxActive {
      padding: theme.spacing(3.5, 2);
    }

    @include breakpoints.up('small') {
      position: relative;
      border-radius: 50px;
      padding: theme.spacing(0, 0, 0, 2);
    
      &.searchBoxActive {
        padding: theme.spacing(0, 0, 0, 3);
      }
    }
  }
}

.wrapper {
  height: 40px;
  width: 100%;
  padding: theme.spacing(0, 0, 0, 2);
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  background: gradients.get('background');
  transition: transitions.get(('width', 'padding'));

  &.searchBoxActive {
    padding: theme.spacing(0, 0, 0, 3);
  }
}

.closeIcon {
  height: 36px;
  width: auto;
  fill: theme.get('textColor');
  margin-left: theme.spacing(1.25);
  transform: rotate(90deg);
}

.icon {
  fill: rgba(theme.get('textColorRgb'), 0.4);
  transition: transitions.get('all', 150ms);
  pointer-events: none;
  height: 32px;
  opacity: 1;
  width: 14px;
  min-width: 14px;
  transform: none;
}

.hiddenSearchIcon {
  @include breakpoints.up('small') {
    opacity: 0;
    width: 0;
    min-width: 0;
    transform: scale(0.75);
  }
}

.inputSearch {
  margin-left: theme.spacing(1);
}

.dropdown {
  position: absolute;
  top: 113px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 56px;
  background-color: theme.palette('white');

  &.loading {
    min-height: 100px;
  }

  @include breakpoints.up('small') {
    top: 0;
    height: auto;
    background-color: transparent;
  }
}

.glassLayer {
  .dropdown & {
    top: 30px;
    height: calc(100% - 130px);
    border-radius: 50px;

    @include breakpoints.up('small') {
      height: calc(100% - 30px);
    }
  }
}

.iconRight {
  margin-right: theme.spacing(2);
  fill: theme.get('textColor');
}

.submit {
  @include baseline.reset-button;
  display: none;
  align-items: center;

  @include breakpoints.up('small') {
    display: flex;
  }
}

.logo {
  height: 32px;
  width: auto;
  cursor: pointer;
}

.logoWrapper {
  display: flex;
  justify-content: space-between;
  margin: theme.spacing(0);
  background-color: theme.palette('white');
  padding: theme.spacing(2.75);

  @include breakpoints.up('small') {
    display: none;
  }
}

.logoAnchor {
  padding-top: theme.spacing(0.5);
}
