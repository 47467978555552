@use 'theme';

.glassLayer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(theme.palette('white'), 0.4);
  z-index: 1;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
  z-index: 1000;
}

.sticky {
  align-items: flex-start;

  & svg {
    position: sticky;
    top: 50vh;
    margin: theme.spacing(5);
  }
}

.top {
  align-items: flex-start;
}

.middle {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}
