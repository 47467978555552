@use 'breakpoints';

@mixin up($key: 'small', $display: block) {
  display: none;

  @include breakpoints.up($key) {
    display: $display;
  }
}

@mixin down($key: 'small', $display: block) {
  display: $display;

  @include breakpoints.up($key) {
    display: none;
  }
}

@mixin between($firstKey, $secondKey, $display: block) {
  display: none;

  @include breakpoints.between($firstKey, $secondKey) {
    display: $display;
  }
}

@mixin except($firstKey, $secondKey, $display: block) {
  display: $display;

  @include breakpoints.between($firstKey, $secondKey) {
    display: none;
  }
}

@mixin hideOnMobile($display: block) {
  @include up('small', $display);
}

@mixin hideOnDesktop($display: block) {
  @include down('small', $display);
}
